//import store from "../../store";
const karriereRoutes = [
  {
    path: "/Karriere",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Karriere.vue"
      ), 
      
  },
];

export default karriereRoutes;
