<template>
  <div>
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"

export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

* {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, Arial, "Nimbus Sans L", sans-serif;
}
.row {
  padding: 0;
  margin: 0;
  
}
.container-fluid{
  padding: 0;
  margin: 0;
  
}

.col-12 {
  padding-left: 0px;
  padding-right: 0px;
}
.dse{
  color:blue;
  text-decoration: underline;
}
.langsam {
  animation: einblenden 2s;
  -moz-animation: einblenden 2s;
  /* Für Firefox */
  -webkit-animation: einblenden 2s;
  /* Für Safari und Chrome */
  -o-animation: einblenden 2s;
  /* Für Opera */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bolder{
  font-size: 18px;
  font-weight: bolder;
}
.titelH3 {
  
  font-size: 20px;
  color: #12314d;
  font-weight: bolder;
}

.titelH4 {
  font-size: 18px;
  font-weight: bolder;
}

.nav {
  background-color: #0060a9;
  padding: 0;
}

html,
body {
  height: 100%;
}

.nav-logo {
  align-items: center;
  min-height: 60px;
}

.logo {
  max-height: 70px;
}

.titlebackground {
  background-color: #004d79;
  min-width: 100%;
}

.topPicture {
  min-height: 700px;
}

.titeltextHeader {
  font-size: 44px;
}
.textblock {
  font-size: 20px;
}
.onTop {
  z-index: 100;
}

.card {
  z-index: 100;
}

.bg-footer {
  background-color: #024b78;
  color: white;
}

.footerHeader {
  background-color: #024b78;
  color: white;
  justify-content: center !important;
 /* border-top: 1px solid #dee2e6 !important;
  border-width: 2px !important;*/
}

.blauStandart {
  background-color: #024b78;
  color: white;
}

.sprachen {
  color: white;
  font-size: 16px;
  padding: 5px;
}

.pointer {
  cursor: pointer;
}

a:hover {
  color: red;
}
.aktiverLink{
  color: red;
}

.nav-link:hover {
  color: red;
}
.sprachen:hover {
  color: red;
}

.grau10 {
  background-color: #f8f9fa;
}

.grau20 {
  background-color: #e9ecef;
}

.grau30 {
  background-color: #dee2e6;
}

.grau40 {
  background-color: #ced4da;
}

.grau50 {
  background-color: #adb5bd;
}

.grau60 {
  background-color: #6c757d;
}

.grau70 {
  background-color: #495057;
}

.grau80 {
  background-color: #343a40;
}

.grau90 {
  background-color: #212529;
}

.borderBlauKlein {
  background-color: white;
  border: 4px solid #0060a9;
  border-radius: 100px;
  padding: 10px;
  max-width: 70%;
}

.borderBlauKlein:hover {
  background-color: white;
  border: 20px solid #73a3c9;
  border-radius: 100px;
  padding: 0px;
}

.borderGrauUnten:hover {
  border: 1px solid #73a3c9;
  border-radius: 100px;
  padding: 0px;
}

.formularButtons {
  background-color: #ffffff;
  border: 4px solid #ffffff;
  border-radius: 100px;
  padding: 20px;
}

.formularButtonsGruen {
  background-color: rgb(71, 214, 78);
  border: 4px solid #ffffff;
  border-radius: 100px;
  padding: 20px;
}

.nav-item {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
  float: left;
  width: 16.66%;
  text-align: center;
}

.navWidth {
  min-width: 50%;
}

.navWidth2 {
  min-width: 100%;
}

.navlink {
  text-decoration: none;
  color: white;
}

.nav-item,
.textEigenFarbe {
  color: white !important;
}

.nav-link {
  color: white;
}

.textCarusellUntenMitte {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  min-width: 90%;

  background-color: white;
  font-size: x-large;
  font-weight: bolder;
  color: #8ac007;
}
.Platzhalter{
  min-height: 500px;
}
.produktBildRad {
  max-width: 85%;
}
.unternehmenBildIcon{
  max-width: 5%;
}
.qualiBild1 {
  max-width: 60%;
}

.qualiBild2 {
  max-width: 30%;
}

.qualiBild3 {
  max-width: 50%;
}

.qualiBild4 {
  max-width: 40%;
}

.unternehmenBild1 {
  margin-top: 20px;
}

.unternehmenBild2 {
  margin-top: 20px;
  max-width: 90%;
}

.unternehmenBild3 {
  margin-top: 20px;
  max-width: 100%;
}

.BereichUeberschrift {
  padding: 50px;
  
  font-size:  clamp(1.9rem, 2.3vw, 3.3rem);
  color: #024b78;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.BereichUeberschriftPicto {
  font-size: 24px;
  background-color: #024b78;
  color: white;
  border-radius: 100px;
  padding: 12px;
  margin-right: 20px;
}

.pictoStart {
  font-size: 24px;
  background-color: #024b78;
  color: white;
  border: 4px solid #024b78;
  border-radius: 100px;
  padding: 10px;
  max-width: 70%;
  margin-right: 20px;
}

.PRIcon {
  align-items: center;
  font-size: 64px;
  background-color: #0b3c8b;
  color: white;
  border: 4px solid #0b3c8b;
  border-radius: 20px;
  padding: 10px;
  max-width: 70%;
  margin-right: 20px;
}

.Produktregulierung {
  font-size: clamp(1.4rem, 2.3vw, 3.3rem);
  
  color: #0b3c8b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.ProduktregulierungKlein {
  font-size: 25px;
  color: #89888a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.KachelTop {
  font-size: 30px;
  color: #0b3c8b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.KachelGross {
  font-size: 36px;
  color: #0b3c8b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.KachelBase {
  font-size: 20px;
  color: #89888a;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.borderBlau {
  border: 4px solid #0b3c8b;
}
.underline {
  border-bottom: solid;
  border-block-end-width: 2px;
  writing-mode: horizontal-tb;
  border-bottom-color: #777879;
  padding: 5px;
}

.centriert {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blocksatz {
  hyphens: auto;
  text-align: justify;
  
}
.middel{
  display: table-cell;
  vertical-align: middle;}
  
.downloadLink {
  color: #0b3c8b;
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.deColor {
  color: #0060a9;
}

.enColor {
  color: #921621;
}

.itColor {
  color: #07940e;
}

.custom-control-label {
  padding-left: 15px;
}

.kontaktLi {
  list-style: none;
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #004d79;
}

.footerDiv p,
.footerDiv i,
.footerDiv a {
  font-size: 16px;
  color: #0060a9;
}

.footerDiv i {
  font-size: 46px;
  color: #0060a9;
}

.footerDiv p a {
  font-size: 16px;
  margin: 0px, auto;
  color: #0060a9;
  text-decoration: none;
}

.footerIcon {
  font-size: 36px;
  margin-bottom: 20px;
  color: white;
}

.ohnePunkt li {
  list-style: none;
}

.fa-star {
  padding-right: 10px;
}

.downloadIcon {
  font-size: 24px;
}

.border-outset {
  border-style: outset;
}

.textBlau {
  color: #0060a9;
}

.stickyLogo {
  position: fixed;
  top: 1px;
  left: 0px;

  z-index: 199;
}

.stickyPfeil {
  position: fixed;
  bottom: 100px;
  right: 50px;
  overflow: auto;
  color: #e62d39 !important;
  text-shadow: 1px 1px 4px white;
  font-size: 40px;
  z-index: 99;
}

.vonLinks {
  position: -webkit-sticky;
  -webkit-animation-name: vonLinks;
  -webkit-animation-duration: 3s;
  -moz-animation-name: vonLinks;
  -moz-animation-duration: 3s;
  animation-name: vonLinks;
  animation-duration: 3s;
}

@-webkit-keyframes vonLinks {
  from {
    -webkit-transform: translate(-600px, 0);
  }

  to {
    -webkit-transform: translate(0, -0px);
  }
}

@-moz-keyframes vonLinks {
  from {
    -moz-transform: translate(-600px, 0);
  }

  to {
    -moz-transform: translate(0, -0px);
  }
}

@keyframes vonLinks {
  from {
    transform: translate(-600px, 0);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes einblenden {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes einblenden {
  /* Für Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes einblenden {
  /* Für Safari und Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes einblenden {
  /* Für Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



@media only screen and (min-width: 1000px) {
  .noMonitor{
    display: none;
  }
  .textAllgemein{    
    padding: 50px;
    font-size: clamp(1.0rem, 1.3vw, 1.3rem);
    }
  .contentP3 {
    padding: 30px;
  }
  .textBorder{
    text-shadow: -1px 1px 0 #000,
    1px 1px 0 #000,
   1px -1px 0 #000,
  -1px -1px 0 #000;
  }
  .titeltext {
    /*background-color: #024b786b;*/
    color: rgb(255, 255, 255);
    
    font-size: 18px;
    font-weight: bold;
    padding: 15px;    
    position: absolute;
    top: 60%;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 50;
   
  }
  .titeltextDetails {
    background-color: rgba(255, 255, 255, 0.658);
    color: black;
    font-size: 14px;
    padding: 30px;
    margin-right: 15px;
  }
  .titeltextDetails2 {
    background-color: #024b78b6;
    color: white;
    font-size: 14px;
    padding: 30px;
    margin-left: 15px;
    min-width: 500px;
  }
}

@media only screen and (max-width: 1000px) {
  .noHandy{
    display: none;
  }
  .kleineSchrift{
    font-size: 10px;
  }
  .titeltext {
    background-color: #024b78b6;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .textAllgemein{    
  font-size: clamp(1.0rem, 1.3vw, 1.3rem);
  }
  .bilderHandylink{
    font-size: 26px;
    color: black;
  }

  .top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color:#024b78b6;
    background: #024b78b6;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #FFF;
    height: 60px;
    padding: 1em;
  }

  .menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .menu > li {
    margin: 0 1rem;
    overflow: hidden;
  }

  .menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #menu-toggle {
    display: none;
  }

  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  .menu-button::before {
    content: "";
    margin-top: -8px;
  }

  .menu-button::after {
    content: "";
    margin-top: 8px;
  }

  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }

  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(133, 69, 69, 0);
  }

  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }

  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #024b78b6;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}
</style>
