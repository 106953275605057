<template>
  <carousel :items-to-show="0.8" :wrap-around="true" :autoplay="15000" :class="topPicture">
    <slide v-for="slide in slides" :key="slide">
      <div>
        <img
        class="img-fluid topPicture noHandy"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"          
          v-bind:class="imgCH " 
          :style="'min-height: '+(windowHeight*0.95)+'px;'"/>      
          <img
        class="img-fluid topPicture noMonitor"
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"          
          v-bind:class="imgCH " 
          :style="'min-height: '+(windowHeight*0.25)+'px;'"/>        
      </div>      
          <div class="textCarusellUntenMitte">{{slide.text}}</div>      
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>



<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);      
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [
      {bild:"aussen3",text:""},
      {bild:"aussen1",text:""},
    {bild:"DSC_0059-2",text:""},
      
    ];
    return {
      windowHeight: window.innerHeight,
      slides,
    };
  },
  mounted() {
            window.onresize = () => {
                this.windowHeight = window.innerHeight;
            }
        }
};
</script>


