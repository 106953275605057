<template>
  <div
    class="card text-center text-vue noHandy"
    style="width: 990px; overflow: hidden; z-index: 200"
    :style="'max-height: ' + windowHeight * 0.9 + 'px;'"
  >
 
    <div class="card-body">
      <div style="height: 620px; overflow-y: auto; overflow-x: hidden">
        <vue-pdf-embed :source="'https://stfg-rudolstadt.com/pdf/' + pdf" />
      </div>

      <button type="button" class="mt-3 btn btn-danger me-5" @click="download()">
        download
      </button>
      <button type="button" class="mt-3 btn btn-danger ma-5" @click="close()">
        Close
      </button>
    </div>
  </div>
  <div
    class="card text-center text-vue noMonitor"
    style="width: 990px; overflow: hidden; z-index: 200"
    :style="'height: 500px;'"
  >
  
    <div class="card-body">
      <div style="height: 430px; overflow-y: auto; overflow-x: hidden">
        <vue-pdf-embed :source="'https://stfg-rudolstadt.com/pdf/' + pdf" />
      </div>
      <button type="button" class="mt-3 btn btn-sm btn-danger me-5" @click="download()">
        Download
      </button>
      <button type="button" class="mt-3 btn btn-sm btn-danger ma-5" @click="close()">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import VuePdfEmbed from "vue-pdf-embed";




export default {
  name: "ModalsPDF",
  props: {
    pdf: String,
  },
  methods: {
    close() {
      closeModal();
    },

    download() {
      var win = window.open(
        "https://stfg-rudolstadt.com/pdf/" + this.pdf,
        "_blank"
      );

      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },

    data() {
      return {
        windowHeight: window.innerHeight,
       
      };
    },
    mounted() {
      window.onresize = () => {
        this.windowHeight = window.innerHeight;
      };
    },
  },
  components: {
    VuePdfEmbed,
  },
  created() {},
};
</script>
