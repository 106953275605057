

import startRoutes from "./start-router";
import impressumRoutes from "./impressum-router";
import kontaktRoutes from "./kontakt-router";
import datenschutzRoutes from "./datenschutz-router";
import karriere from "./karriere-router";
import produkteRoutes from "./produkte-router";
import qualitaetRoutes from "./qualitaet-router";
import unternehmenRoutes from "./unternehmen-router";
import AGB_En from "./aGB_EN-router";
import AGB_De from "./aGB_De-router";


const routes = [ 
                ...startRoutes,
                ...impressumRoutes,
                ...datenschutzRoutes,
                ...karriere,
                ...kontaktRoutes,
                ...produkteRoutes,
                ...qualitaetRoutes,
                ...unternehmenRoutes,
                ...AGB_De,
                ...AGB_En,
            ];

export default routes;
