//import store from "../../store";
const produkteRoutes = [
  {
    path: "/Produkte",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Produkte.vue"
      ), 
      
  },
];

export default produkteRoutes;
