import { createStore } from "vuex";

import mainModule from "./modules/main";
import sprachModule from "./modules/sprachSelect";
const store = createStore({
  modules: {      
    suw:mainModule,
    sprachModule:sprachModule,
  },
});

export default store;
