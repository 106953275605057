//import store from "../../store";
const impressumRoutes = [
  {
    path: "/aGB_En",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/AGB_EN.vue"
      ), 
      
  },
];

export default impressumRoutes;
