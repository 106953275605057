<template>
  <!-- <meta name="viewport" content="width=device-width, initial-scale=1" /> -->

  <div class="titlebackground container sticky-top headerHeight m-0">
    <router-link to="/Start">
      <img
        src="../assets/logoweissHA2.png"
        class="logo img-fluid align-middle stickyLogo noHandy"
        alt="..."
      />
    </router-link>
    <div class="row noHandy">
      <div class="col-8 offset-2">
        <nav class="navbar navbar-expand">
          <ul class="navbar-nav navWidth2">
            <li class="nav-item active handy-ps2">
              <router-link to="/Unternehmen" class="nav-link">
                <span v-if="sprachID === 0"
                v-bind:class="[routeName=='Unternehmen' ? 'aktiverLink' : '', ]"
                >Unternehmen</span>
                <span v-if="sprachID === 1"
                v-bind:class="[routeName=='Unternehmen' ? 'aktiverLink' : '', ]"
                >Company</span>
              </router-link>
            </li>
            <li class="nav-item active handy-ps2">
              <router-link to="/Produkte" class="nav-link">
                <span v-if="sprachID === 0"
                v-bind:class="[routeName=='Produkte' ? 'aktiverLink' : '', ]"
                >Produkte</span>
                <span v-if="sprachID === 1"
                v-bind:class="[routeName=='Produkte' ? 'aktiverLink' : '', ]"
                >Products</span>
              </router-link>
            </li>
            <li class="nav-item active handy-ps2">
              <router-link to="/Qualitaet" class="nav-link">
                <span v-if="sprachID === 0"
                v-bind:class="[routeName=='Qualitaet' ? 'aktiverLink' : '', ]"
                >Qualität</span>
                <span v-if="sprachID === 1"
                v-bind:class="[routeName=='Qualitaet' ? 'aktiverLink' : '', ]"
                >Quality</span>
              </router-link>
            </li>
            <li class="nav-item active handy-ps2">
              <router-link to="/Karriere" class="nav-link">
                <span v-if="sprachID === 0"
                v-bind:class="[routeName=='Karriere' ? 'aktiverLink' : '', ]"
                >Karriere</span>
                <span v-if="sprachID === 1"
                v-bind:class="[routeName=='Karriere' ? 'aktiverLink' : '', ]"
                >Career</span>
              </router-link>
            </li>
            <li class="nav-item active handy-ps2"
            >
              <router-link to="/Kontakt" class="nav-link"
              >
                <span v-if="sprachID === 0"
            v-bind:class="[routeName=='Kontakt' ? 'aktiverLink' : '', ]"
            >Kontakt</span>
                <span v-if="sprachID === 1"
                v-bind:class="[routeName=='Kontakt' ? 'aktiverLink' : '', ]"
                >Contact</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      
      <div class="col-2 text-end pt-2">
        <span class="sprachen pointer" @click="changeSprache(0)">DE</span>
        <span class="sprachen">|</span>
        <span class="sprachen pointer" @click="changeSprache(1)">EN</span>
      </div>
    </div>
    <div class="row noMonitor">
      <div class="col-8">
        <img
          src="../assets/logoweissHA2.png"
          class="logo img-fluid align-middle stickyLogo"
          alt="..."
        />
      </div>
      
      <div class="col-4">
        <section class="top-nav">
          <span @click="hamburger=true"><i class='fas fa-bars' style='font-size:24px'></i></span>
          <!-- <input id="menu-toggle" type="checkbox" v-model="hamburger" />
          <label class="menu-button-container" for="menu-toggle">
            <div class="menu-button"></div>
          </label>
          <ul class="menu"> -->
            <ul class="menu"
             v-if="hamburger==true">
            <li class="nav-item  handy-ps2">
              <router-link to="/" class="nav-link">
                <span v-if="sprachID === 0">Home</span>
                <span v-if="sprachID === 1">Home</span>
              </router-link>
            </li>
            <li class="nav-item  handy-ps2">
              <router-link to="/Unternehmen" class="nav-link">
                <span v-if="sprachID === 0">Unternehmen</span>
                <span v-if="sprachID === 1">Company</span>
              </router-link>
            </li>
            <li class="nav-item  handy-ps2">
              <router-link to="/Produkte" class="nav-link">
                <span v-if="sprachID === 0">Produkte</span>
                <span v-if="sprachID === 1">Products</span>
              </router-link>
            </li>
            <li class="nav-item  handy-ps2">
              <router-link to="/Qualitaet" class="nav-link">
                <span v-if="sprachID === 0">Qualität</span>
                <span v-if="sprachID === 1">Quality</span>
              </router-link>
            </li>
            <li class="nav-item  handy-ps2">
              <router-link to="/Karriere" class="nav-link">
                <span v-if="sprachID === 0">Karriere</span>
                <span v-if="sprachID === 1">Career</span>
              </router-link>
            </li>
            <li class="nav-item  handy-ps2">
              <router-link to="/Kontakt" class="nav-link">
                <span v-if="sprachID === 0">Kontakt</span>
                <span v-if="sprachID === 1">Contact</span>
              </router-link>
            </li>
          </ul>
        </section>
      </div>
      <div class="col-2 text-end pt-2">
        <span class="sprachen pointer" @click="changeSprache(0)">DE</span>
        <span class="sprachen">|</span>
        <span class="sprachen pointer" @click="changeSprache(1)">EN</span>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useRoute } from 'vue-router'

export default {
  name: "TheNavbar",
  setup() {
        const route = useRoute();
        const routeName = route.path.slice(1); //route.path will return /name

        return {
            routeName
        }
    },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  methods: {
    changeSprache(sprachwert) {
      const store = this.$store;
      store.dispatch("setSprachID", sprachwert).then(() => {});
    },
  },
  data() {
    return {
      hamburger: false,      

    };
  },
  created() {},
};
</script>

<style scoped></style>
