<template>
  <div
    class="footer onTop p-0 m-0 bg-footer"
    @mouseover="footerAktiv = true"
    @mouseleave="footerAktiv = false"
  >
    <div class="row footerHeader justify-content-center noHandy">
      <div class="col-sm-12 col-md-11">
        <div class="row justify-content-center centriert p-1">
          <div class="col-sm-2 col-md-2 text-center">
            <i class="far fa-address-card footerIcon2"></i>
          </div>
          <div class="col-sm-2 col-md-2 text-center">
            <i class="fas fa-phone footerIcon2"></i>
          </div>
          <div class="col-sm-2 col-md-2 text-center">
            <i class="far fa-envelope-open footerIcon2"></i>
          </div>

          <div class="col-sm-2 col-md-2 text-center">
            <span
              v-if="sprachID === 0"
              v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
            >
              <router-link
                to="/AGB_De"
                class="nav-link p-0"
                @click="activesite = 'AGB_De'"
              >
                <i
                  class="fas fa-landmark footerIcon2"
                  v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
                ></i
              ></router-link>
            </span>
            <span
              v-if="sprachID === 1"
              v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
            >
              <router-link
                to="/AGB_De"
                class="nav-link p-0"
                @click="activesite = 'AGB_De'"
                ><i
                  class="fas fa-landmark footerIcon2"
                  v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
                ></i
              ></router-link>
            </span>
          </div>

          <div class="col-sm-2 col-md-2 text-center">
            <router-link
              to="/Impressum"
              class="nav-link p-0"
              @click="activesite = 'Impressum'"
              v-bind:class="[routeName == 'Impressum' ? 'aktiverLink' : '']"
            >
              <i class="far fa-building footerIcon2"
              v-bind:class="[routeName == 'Impressum' ? 'aktiverLink' : '']"></i
            ></router-link>
          </div>
          <div class="col-sm-2 col-md-2 text-center">
            <router-link
              to="/Datenschutz"
              class="nav-link p-0"
              @click="activesite = 'Datenschutz'"
            >
              <i class="far fa-file-alt footerIcon2"
              v-bind:class="[routeName == 'Datenschutz' ? 'aktiverLink' : '']"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-footer justify-content-center noHandy" v-if="footerAktiv == true">
      <div class="col-sm-12 col-md-11">
        <div class="row justify-content-center p-1">
          <div v-if="sprachID === 0" class="col-sm-2 col-md-2 text-center">
            Geschäftszeiten: 8:00 – 16:00
          </div>
          <div v-if="sprachID === 1" class="col-sm-2 col-md-2 text-center">
            Office Hours: 8:00 – 16:00
          </div>
          <div class="col-sm-2 col-md-2 text-center">
            <p>+49 (0) 3672 313 0</p>
          </div>
          <div class="col-sm-2 col-md-2 text-center">info@stfg-rudolstadt.com</div>

          <div class="col-sm-2 col-md-2 text-center">
            <span v-if="sprachID === 0" >
              <router-link
                to="/AGB_De"
                class="nav-link p-0"
                @click="activesite = 'AGB_De'"               
              >
               <span
               v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']">AGB</span> </router-link
              >
            </span>
            <span v-if="sprachID === 1">
              <router-link
                to="/AGB_De"
                class="nav-link p-0"
                @click="activesite = 'AGB_De'"
              >
                <span
                  v-if="sprachID === 1"
                  v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
                  >Terms & Conditions</span
                ></router-link
              >
            </span>
          </div>

          <div class="col-sm-2 col-md-2 text-center">
            <router-link
              to="/Impressum"
              class="nav-link p-0"
              @click="activesite = 'Impressum'"
            >
              <span v-if="sprachID === 0"
              v-bind:class="[routeName == 'Impressum' ? 'aktiverLink' : '']">Impressum</span>
              <span v-if="sprachID === 1"
              v-bind:class="[routeName == 'Impressum' ? 'aktiverLink' : '']">Legal Notice</span></router-link
            >
          </div>
          <div class="col-sm-2 col-md-2 text-center">
            <router-link
              to="/Datenschutz"
              class="nav-link p-0"
              @click="activesite = 'datenschutz'"
            >
              <span v-if="sprachID === 0"
              v-bind:class="[routeName == 'Datenschutz' ? 'aktiverLink' : '']">Datenschutz</span>
              <span v-if="sprachID === 1"
              v-bind:class="[routeName == 'Datenschutz' ? 'aktiverLink' : '']">Privacy Policy</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="noMonitor bg-footer justify-content-center kleineSchrift">
      <div class="row justify-content-center bg-footer centriert p-1">
        <div class="col-4 text-center">
          <a href="mailto:info@stfg-rudolstadt.com" class="nav-link p-0">
            <i class="far fa-address-card footerIcon2"></i>
            <div v-if="sprachID === 0" >
              Geschäftszeiten: 8:00 – 16:00
            </div>
            <div v-if="sprachID === 1">
              Office Hours: 8:00 – 16:00
            </div>
          </a>
        </div>
        <div class="col-4 text-center">
          <i class="fas fa-phone footerIcon2"></i> <br />+49 (0) 3672 313 0 <br />&nbsp;
        </div>
        <div class="col-4 text-center">
          <i class="far fa-envelope-open footerIcon2"></i><br />info@stfg-rudolstadt.com
        </div>
      </div>
      <div class="row justify-content-center bg-footer centriert p-1">
        <div class="col-4 text-center">
          <span
            v-if="sprachID === 0"
            v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
          >
            <router-link to="/AGB_De" class="nav-link p-0" @click="activesite = 'AGB_De'">
              <i class="fas fa-landmark footerIcon2"
              v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"></i><br />AGB</router-link
            >
          </span>
          <span
            v-if="sprachID === 1"
            v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
          >
            <router-link to="/AGB_De" class="nav-link p-0" @click="activesite = 'AGB_De'"
              ><i
                class="fas fa-landmark footerIcon2"
                v-bind:class="[routeName == 'AGB_De' ? 'aktiverLink' : '']"
              ></i
              ><br />
              <div v-if="sprachID === 0" >AGB</div>
              <div v-if="sprachID === 1">Terms & Conditions</div>
              </router-link
            >
          </span>
        </div>
        <div class="col-4 text-center">
          <router-link
            to="/Impressum"
            class="nav-link p-0"
            @click="activesite = 'impressum'"
          >
            <i class="far fa-building footerIcon2"
            v-bind:class="[routeName == 'impressum' ? 'aktiverLink' : '']"></i><br />
            <div v-if="sprachID === 0" >Impressum</div>
              <div v-if="sprachID === 1">Legal Notice</div>
              </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link
            to="/Datenschutz"
            class="nav-link p-0"
            @click="activesite = 'datenschutz'"
          >
            <i class="far fa-file-alt footerIcon2"
            v-bind:class="[routeName == 'datenschutz' ? 'aktiverLink' : '']"></i><br />
            <div v-if="sprachID === 0" >Datenschutz</div>
            <div v-if="sprachID === 1">Privacy Policy</div>
          </router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";
import { useRoute } from "vue-router";

export default {
  name: "TheNavbar",

  setup() {
    const route = useRoute();
    const routeName = route.path.slice(1); //route.path will return /name

    return {
      routeName,
    };
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  data() {
    return {
      activesite: "",
      footerAktiv: false,
    };
  },
  created() {},
};
</script>

<style scoped></style>
