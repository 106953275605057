// import { createRouter, createWebHistory } from "vue-router";
 
// // import ShopPage from "@/pages/ShopPage.vue";
// // import ReadProductPage from "@/pages/ReadProductPage.vue";
// import routes from "./routes";
// import NotFoundPage from "@/pages/Start.vue";

import { createRouter, createMemoryHistory } from "vue-router";
import routes from "./routes";
import NotFoundPage from "@/pages/Start.vue";
import store from "../store";

const router = createRouter({
  // Optionen
  history: createMemoryHistory(),
  mode: 'hash',
  //scrollBehavior() {
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return { el: to.hash };
      }
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundPage,
      redirect: "/",
    },
  ],
});

router.afterEach((to) => {
  store.commit('setActiveSite',to.fullPath);  
})

export default router;

// import store from "../store";

// const router = createRouter({
//   // Optionen
//   history: createWebHistory(),
//   mode: 'hash',
 
  
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (to.hash) {
//       return { el: to.hash };
//     }
//    // return { x: 0, y: 0 };
//    return { top: 0 }
//   },
//   routes: [
//     ...routes,
//     {
//       path: "/:pathMatch(.*)*",
//       component: NotFoundPage,
//       redirect: "/",
//     },
//   ],
// });

// router.afterEach((to) => {
//   store.commit('setActiveSite',to.fullPath);
// })

// export default router;

// // www.shop.de/auth/login
// // www.shop.de/index.html/#/auth/login
