//import store from "../../store";
const qualitaetRoutes = [
  {
    path: "/Qualitaet",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Qualitaet.vue"
      ), 
      
  },
];

export default qualitaetRoutes;
