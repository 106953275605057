<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0">
        <div class="row justify-content-center text-uppercase p-0 m-0">
          <div class="col-12">
            <div class="titeltext text-center">
              <span v-if="sprachID === 0" class="titeltextHeader textBorder"
                >Ihr Lieferant für PA6-Compounds</span
              >
              <span v-if="sprachID === 1" class="titeltextHeader textBorder"
                >Your provider of PA6-Compounds</span
              >
              <!-- <span v-if="sprachID === 1" class="titeltextHeader textBorder ">Your provider of PA6-Compounds</span> -->
              <div class="row justify-content-center text-uppercase pt-3">
                <div v-if="sprachID === 0" class="col-5 titeltextDetails2">
                  <span class="bolder">
                    Sie haben eine Herausforderung. <br />
                    Wir finden die Lösung!</span
                  >
                </div>
                <div v-if="sprachID === 1" class="col-5 titeltextDetails2">
                  <span class="bolder">
                    You are confronted by challenge. <br />
                    we will find solutions!</span
                  >
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        <CarouselHome></CarouselHome>      
      
        <div class="row justify-content-center text-uppercase pt-5 m-0">
          <div class="col-10">
            <div class="BereichUeberschrift">
              <i class="fas fa-flask BereichUeberschriftPicto"></i>
              <span v-if="sprachID === 0">Produkte </span>
              <span v-if="sprachID === 1">Products </span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-4 m-0 m-0">
          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#gl1" class="nav-link noHandy">
              <img
                src="../assets/p0.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#gl1h" class="nav-link noMonitor">
              <img
                src="../assets/p0.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Glasfaserverstärkt </span>
                <span v-if="sprachID === 1"> Glass Reinforced </span>
              </div>
            </router-link>
          </div>

          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#hi1" class="nav-link noHandy">
              <img
                src="../assets/p0.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#hi1h" class="nav-link noMonitor">
              <img
                src="../assets/p0.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Hitzestabilisiert </span>
                <span v-if="sprachID === 1"> Heat Stabilized </span>
              </div>
            </router-link>
          </div>

          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#fa1" class="nav-link noHandy">
              <img
                src="../assets/p3c.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#fa1h" class="nav-link noMonitor">
              <img
                src="../assets/p3c.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Farbstabilisiert </span>
                <span v-if="sprachID === 1"> Color Stabilized </span>
              </div>
            </router-link>
          </div>

          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#re1" class="nav-link noHandy">
              <img
                src="../assets/pr2.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#re1h" class="nav-link noMonitor">
              <img
                src="../assets/pr2.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Recycelt </span>
                <span v-if="sprachID === 1"> Recycled </span>
              </div>
            </router-link>
          </div>
        </div>

        <div class="row mb-4 justify-content-center pb-5 m-0 noHandy">
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Glasfaserverstärkt</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Glass Reinforced</h4>
            </span>
          </div>
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Hitzestabilisiert</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Heat Stabilized</h4>
            </span>
          </div>
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Farbstabilisiert</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Color Stabilized</h4>
            </span>
          </div>

          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Recycelt</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Recycled</h4>
            </span>
          </div>
        </div>

        <div class="row m-0 pt-5 justify-content-center">
          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#po6" class="nav-link noHandy">
              <img
                src="../assets/p1.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#po6h" class="nav-link noMonitor">
              <img
                src="../assets/p1.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Polyamid 6 </span>
                <span v-if="sprachID === 1"> Polyamide 6 </span>
              </div>
            </router-link>
          </div>

          <div class="col-lg-2 col-md-12 text-center">
            <router-link to="/Produkte#gf2" class="nav-link noHandy">
              <img
                src="../assets/p1.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#gf2h" class="nav-link noMonitor">
              <img
                src="../assets/p1.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> Unverstärktes Polyamid 6 </span>
                <span v-if="sprachID === 1"> Unreinforced Polyamide 6 </span>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-md-2 col-sm-12 text-center">
            <router-link to="/Produkte#urea" class="nav-link noHandy">
              <img
                src="../assets/urea.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#ureah" class="nav-link noMonitor">
              <img
                src="../assets/urea.png"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> UREA </span>
                <span v-if="sprachID === 1"> UREA </span>
              </div>
            </router-link>
          </div> -->

          <!-- <div class="col-md-2 col-sm-12 text-center">
            <router-link to="/Produkte#urea" class="nav-link noHandy">
              <img
                src="../assets/fluessig.jpg"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
            </router-link>
            <router-link to="/Produkte#ureah" class="nav-link noMonitor">
              <img
                src="../assets/fluessig.jpg"
                class="img-fluid align-middle borderBlauKlein"
                alt="..."
              />
              <div class="bilderHandylink">
                <span v-if="sprachID === 0"> UAN-32 </span>
                <span v-if="sprachID === 1"> UAN-32 </span>
              </div>
            </router-link>
          </div> -->
        </div>
        <div class="row m-0  justify-content-center pb-5 noHandy">
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Polyamid 6</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Polyamide 6</h4>
            </span>
          </div>
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">Unverstärktes Polyamid 6</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">Unreinforced Polyamide 6</h4>
            </span>
          </div>
          <!-- <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">UREA</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">UREA</h4>
            </span>
          </div>
          <div class="col-md-2 col-sm-12 text-center">
            <span v-if="sprachID === 0">
              <h4 class="pt-4">UAN-32</h4>
            </span>
            <span v-if="sprachID === 1">
              <h4 class="pt-4">UAN-32</h4>
            </span>
          </div> -->
        </div>

        <div class="row justify-content-center pt-4 grau20 m-0">
          <div class="col-10 pt-3">
            <div class="BereichUeberschrift">
              <i class="fas fa-microscope BereichUeberschriftPicto"></i>
              <span v-if="sprachID === 0">QUALITÄT </span>
              <span v-if="sprachID === 1">QUALITY </span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center px-5 pt-4 pb-3 grau20 m-0">
          <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img
              src="../assets/iso_9001.jpg"
              class="img-fluid align-middle"
              alt="iso_9001"
            />
            <p class="pt-3">
              <span
                class="downloadLink pe-5 pointer"
                v-if="sprachID === 0"
                @click="openPDF('STFG_ISO9001_2022_de.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <br class="noMonitor" />
              <span
                class="downloadLink pointer"
                @click="openPDF('STFG_ISO9001_2022_en.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>
          </div>
          <div class="col-md-3 col-sm-12 text-center bg-white borderBlau m-3">
            <img
              src="../assets/iso_50001.jpg"
              class="img-fluid align-middle"
              alt="iso_50001"
            />
            <p class="pt-3">
              <span
                class="downloadLink pe-5 pointer"
                v-if="sprachID === 0"
                @click="openPDF('STFG_ISO50001_2022_de.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (DE)
              </span>
              <br class="noMonitor" />
              <span
                class="downloadLink pointer"
                @click="openPDF('STFG_ISO50001_2022_en.pdf')"
              >
                <i class="far fa-file-alt"></i> Download (EN)
              </span>
            </p>
          </div>
        </div>
        <div class="row justify-content-center px-3 pb-5 grau20 m-0">
          <div class="col-md-6 text-center bg-white borderBlau mb-5">
            <div class="row">
              <div class="col-3 centriert pt-5">
                <img
                  src="../assets/globus.png"
                  class="img-fluid align-middle qualiBild1"
                  alt="iso_9001"
                />
              </div>
              <div class="col-9 pt-5">
                <p class="Produktregulierung underline" v-if="sprachID === 0">
                  Produktregulierung
                </p>
                <p class="Produktregulierung underline" v-if="sprachID === 1">
                  Regulatory Information
                </p>
                <p class="ProduktregulierungKlein">REACH, RoHS …</p>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12 pb-3">
                <span
                  class="downloadLink pe-5 pb-3 pointer"
                  v-if="sprachID === 0"
                  @click="openPDF('ProduktregulationDE.pdf')"
                >
                  <i class="far fa-file-alt"></i> Download (DE)
                </span>
                <br class="noMonitor" />
                <span
                  class="downloadLink pointer"
                  @click="openPDF('ProduktregulationEN.pdf')"
                >
                  <i class="far fa-file-alt"></i> Download (EN)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import CarouselHome from "../components/carouselHome.vue";
import modalPDF from "../components/ModalPDF.vue";
import { openModal } from "jenesius-vue-modal";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      windowHeight: window.innerHeight, 

    };
  },
  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
  },
  components: {
    Layout,
    CarouselHome,
  },
  methods: {
    openPDF(pdf) {
      const props = { pdf: pdf };
      openModal(modalPDF, props);
    },
  },
  computed: {
    user() {
      let user = this.$store.getters.getUSER;
      return user;
    },
    sprachID() {
      let sprachID = this.$store.getters.getSprachID;
      return sprachID;
    },
  },
};
</script>

<style scoped></style>
